import { Table, Typography, ConfigProvider, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import {
    Row,
    Modal,
    Card,
    Button,
    Col,
    Popover,
    Divider
} from "antd"

// icons
import modalTitleICon from "../assets/icon/radar.png"


const { Text } = Typography;

const VideoPlayer = ({ videoUrl }) => {
    return (
        <div style={{ borderRadius: '8px', overflow: 'hidden', height: "150px" }}>
            <iframe
                title="videoPlayer"
                width="100%"
                src={videoUrl}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        </div>
    );
};

const TreatmentPlanTable = (props) => {
    const [showModal, setShowModal] = useState(false)
    const [showModalID, setShowModalID] = useState(false)
    const [data, setData] = useState([])

    const handleShowModal = (record) => {
        console.log(record.key)
        setShowModalID(record.key)
        setShowModal(true)
    }

    useEffect(() => {
        if (props.data) {
            // Flatten the data to include each treatment plan as a separate row
            const flattenedData = props.data.flatMap(item =>
                item.treatment_plans.map(tp => ({
                    key: tp.id,
                    patient: item.patient,
                    note: item.note,
                    status: item.status,
                    created_date: item.created_date,
                    updated_date: item.updated_date,
                    name: tp.name,
                    tooth: tp.tooth_from,
                    estimated_cost: tp.estimated_cost,
                    priority: tp.priority,
                    insurance_coverage: tp.insurance_coverage,
                    discount: tp.discount || '-', // Default value if `discount` is not available
                    patient_responsibility: tp.patient_responsibility || '-', // Default value if `patient_responsibility` is not available
                    video_link: tp.video_link,
                    treatment_procedures: tp.treatment_procedures,
                }))
            );
            setData(flattenedData);
            console.log(flattenedData); // Log the flattened data to verify its structure
        }
    }, [props.data]);
    
    
    
    
    

    const columns = [
        {
            title: 'Priority',
            dataIndex: 'priority',
            key: 'priority',
            sorter: (a, b) => a.priority - b.priority,
            defaultSortOrder: 'ascend',
            width: 80,
            render: (priority) => (
                <Row justify={"center"}>
                    {priority !== null ? priority : "-"}
                </Row>
            ),
        },
        {
            title: 'Treatment',
            dataIndex: 'name',
            key: 'name',
            width: 250,
            render: (name) => (
                <Row justify={name ? "" : "center"}>
                    {name ? name : "-"}
                </Row>
            ),
        },
        {
            title: 'Tooth / Quadrant',
            dataIndex: 'tooth',
            key: 'tooth',
            render: (tooth) => (
                <Row justify={tooth ? "" : "center"}>
                    {tooth ? tooth : "-"}
                </Row>
            ),
        },
        {
            title: "Estimated Cost",
            dataIndex: "estimated_cost",
            key: "estimated_cost",
            width: '25%',
            render: (estimated_cost) => (
                <Row justify={estimated_cost ? "" : "center"}>
                    {estimated_cost ? parseFloat(estimated_cost).toFixed(2) : "-"}
                </Row>
            )
        },
        {
            title: "Insurance Coverage",
            dataIndex: "insurance_coverage",
            key: "insurance_coverage",
            width: '25%',
            render: (insurance_coverage) => (
                <Row justify={insurance_coverage ? "" : "center"}>
                    {insurance_coverage ? insurance_coverage : "-"}
                </Row>
            )
        },
        {
            title: "Your Responsibility",
            dataIndex: "patient_responsibility",
            key: "patient_responsibility",
            width: '25%',
            render: (patient_responsibility) => (
                <Row justify={patient_responsibility ? "" : "center"}>
                    {patient_responsibility ? patient_responsibility : "-"}
                </Row>
            )
        },
        {
            title: "Discount",
            dataIndex: "discount",
            key: "discount",
            width: '25%',
            render: (discount) => (
                <Row justify={discount ? "" : "center"}>
                    {discount ? discount : "-"}
                </Row>
            )
        },
        {
            title: 'Patient Education',
            dataIndex: 'video_link',
            key: 'video_link',
            width: 180,
            render: (video_link, record) =>
                <Popover
                    showArrow={false}
                    placement='bottomRight'
                    content={() => (
                        <div className='card-in-modal'>
                            <div className='insideCardModal'>
                                <Typography style={{ fontSize: "12px", fontWeight: "500" }}>
                                    Learn More:
                                </Typography>
                                <br />
                                <Row align={"middle"}>
                                    <Col>
                                        <img height={35} width={30} src={modalTitleICon} alt="radar" className='mr10' />
                                    </Col>
                                    <Col>
                                        <Typography style={{ fontSize: "11px", fontWeight: "500", color: "#6B43B5" }}>
                                            {record.procedure && record.procedure.procedure_code_description}
                                        </Typography>
                                        <Typography style={{ fontSize: "8px", fontWeight: "500", color: "#979797" }}>
                                            Procedure Code: {record.procedure && record.procedure.procedure_code}
                                        </Typography>
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Typography style={{ fontSize: "9px", fontWeight: "400", color: "#979797", textAlign: "justify" }}>
                                        {record.procedure && record.procedure.description}
                                    </Typography>
                                </Row>
                                <br />
                                <div>
                                    <VideoPlayer videoUrl={video_link} />
                                </div>
                                <br />
                                <div>
                                    <Row justify={"center"}>
                                        <Button type="primary" style={{ width: "240px", height: '26px', fontSize: '10px', fontWeight: '400' }}>
                                            <span style={{ fontSize: "12px" }}>
                                                Request Appointment
                                            </span>
                                        </Button>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    )} trigger="click">
                    <Text onClick={() => { handleShowModal(record) }} underline style={{ color: '#6B43B5', textDecoration: "underline", cursor: "pointer" }}>Learn More</Text>
                </Popover>
        },
    ];
    

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: "#983cfc",
                    controlItemBgHover: "#c293ff",
                    colorLink: '#983cfc',
                },
                components: {
                    Table: {
                        borderRadius: "8px",
                        borderColor: "#eee",
                    },
                },
            }}
        >
            <Table
                dataSource={data}
                columns={columns}
                bordered
                pagination={false}
                expandable={{
                    expandedRowRender: (record) => {
                        console.log(data)
                        // Safely find the treatment plan
                        const treatmentPlan = props.data.length > 0 && props.data[0].treatment_plans && Array.isArray(props.data[0].treatment_plans) && props.data[0].treatment_plans.length > 0 && props.data[0].treatment_plans.find(
                            (plan) => plan.id == record.key // Get the part after '3-'
                        );

                        console.log(record.key)



                        // If treatmentPlan or treatmentPlan.procedures is missing, return null

                        if (!treatmentPlan) {
                            return <p>No treatment plans available</p>;
                        }

                        // Render the procedures if available
                        return (
                            <div style={{ marginLeft: 130 }}>
                                {treatmentPlan.treatment_procedures && treatmentPlan.treatment_procedures.length > 0 ? (
                                    <>
                                        {treatmentPlan.treatment_procedures.map((procedure, procIndex) => (
                                            <React.Fragment key={procIndex}>
                                                <Row
                                                    style={{ display: 'flex', flexDirection: 'row', marginBottom: 25 }}
                                                    gutter={20}
                                                >
                                                    <Col span={3}>{procedure.procedure_code.procedure_code_description || '-'}</Col>
                                                    {/* <Col span={2}>{procedure.note || "-"}</Col> */}
                                                    <Col span={1} style={{ marginRight: 35 }}>{procedure.tooth || '-'}</Col>
                                                    <Col span={3} style={{marginRight: 55}} >
                                                        {procedure.estimated_cost || '-'}
                                                    </Col>
                                                    <Col span={4} style={{ marginRight: 15 }}>{procedure.insurance_estimate || '-'}</Col>
                                                    <Col span={3} style={{ marginRight: 75 }}>{procedure.patient_responsibility || '-'}</Col>
                                                    <Col span={3}>{procedure.discount || '-'}</Col>
                                                </Row>
                                                <Divider />
                                            </React.Fragment>
                                        ))}
                                    </>
                                ) : (
                                    <p>no tratment found</p>
                                )
                                }

                            </div>
                        );
                    }
                }}
            />

        </ConfigProvider>

    );
}

export default TreatmentPlanTable;
